import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./home.scss";
import logo from "../Assets/logo_transparent.svg";
import MainLogo from "../Assets/main_logo.webp";
import discord from "../Assets/discord_svg.svg";
import twitter from "../Assets/twitter_svg.svg";
import discord2 from "../Assets/discord2_svg.svg";
import twitter2 from "../Assets/twitter2_svg.svg";
import farmingcard from "../Assets/farming.webp";
import fishingcard from "../Assets/fishing.webp";
import dungeoncard from "../Assets/dungeon.webp";
import Friends from "../Assets/friends.webp";
import Characters from "../Assets/characters.webp";
import cloud4 from "../Assets/cloud_4.svg";
import meowy from "../Assets/meowy confident.webp";
import bento from "../Assets/Bento hey.webp";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import logom from "../Assets/mob/logo_transparent.svg";
import MainLogom from "../Assets/mob/Logo_Color_MedMoo.webp";
import discordm from "../Assets/mob/discord_svg.svg";
import twitterm from "../Assets/mob/twitter_svg.svg";
import discord2m from "../Assets/mob/discord2_svg.svg";
import twitter2m from "../Assets/mob/twitter2_svg.svg";
import farmingcardm from "../Assets/mob/farming.webp";
import fishingcardm from "../Assets/mob/fishing.webp";
import dungeoncardm from "../Assets/mob/dungeon.webp";
import Friendsm from "../Assets/mob/friends.webp";
import Charactersm from "../Assets/mob/characters.webp";
import cloud4m from "../Assets/mob/cloud_4.svg";
import meowym from "../Assets/mob/meowy confident.webp";
import bentom from "../Assets/mob/Bento hey.webp";
export default function Home() {
  return (
    <>
      <div className="Page">
        <Container fluid className="WelcomeMeadow">
          <Container fluid>
            <Row className="NavBarRow">
              <Col>
                <img src={logo} alt="" className="LogoImg" />
              </Col>
              <Col>
                <Row className="align-items-end">
                  <Col className="soccol">
                    <a href="https://Discord.gg/meadowmoon" className="soclink">
                      <img src={discord} alt="" className="SocImg" />
                    </a>
                    <a
                      href="https://twitter.com/MeadowMoon"
                      className="soclink"
                    >
                      <img src={twitter} alt="" className="SocImg" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <img src={MainLogo} alt="" className="MainLogo" />

          <a href="#Subscribe">
            <button className="SignUpBtn"></button>
          </a>
        </Container>
        <Container fluid className="CloudBG">
          <div className="Clouds">
            <Row>
              <Col>
                <h1 className="WelcomeH1">
                  <span className="Welcometo">WELCOME TO</span>
                  <br />
                  MEADOW MOON
                </h1>
                <p className="WelcomeTxt">
                  Welcome to the world of Meadow Moon, an adventure full of
                  villagers to befriend, land to farm on and dungeons to
                  plunder. Begin your island journey by creating and customizing
                  your character, completing various quests, exploring hidden
                  realms, and uncovering the forgotten history of the island.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid className="GameplayBG">
          <Row>
            <Col className="darksht">
              <h1 className="GameplayH1">GAMEPLAY</h1>
              <p className="GameplayTxt">
                Live in harmony with the villagers of the island while farming
                crops, trading goods and creating your own island paradise. Or
                for those who seek adventure, dive into magical rifts to visit
                mysterious realms and complete various missions, from simple
                tasks like rescuing lost villagers to fighting powerful foes,
                rewarding you with otherworldly treasures.
              </p>
            </Col>
          </Row>
          <Row className="CardRow">
            <Card className="GameplayCard">
              <Card.Img variant="top" src={farmingcard} />
              <Card.Body>
                <Card.Title className="farmingcardtxt">
                  PERSONAL ISLAND
                </Card.Title>
                <Card.Text className="carddescript">
                  Customize your personal island, Grow unique plants, Fill your
                  aquarium with exotic fish, Personalize your island with
                  various decorations and accessories.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="GameplayCard middlecard">
              <Card.Img variant="top" src={fishingcard} />
              <Card.Body>
                <Card.Title className="fishingcardtxt">STORY</Card.Title>
                <Card.Text className="carddescript">
                  Explore both the island and various hidden realms to help
                  uncover the forgotten history of the island.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="GameplayCard">
              <Card.Img variant="top" src={dungeoncard} />
              <Card.Body>
                <Card.Title className="dungeoncardtxt">ADVENTURE</Card.Title>
                <Card.Text className="carddescript">
                  Mysterious rifts begin to appear on the island, which appear
                  to lead to unknown realms within the cosmos. Complete complex
                  puzzles, discover exotic wildlife, unearth mystical treasures,
                  and encounter formidable bosses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Container>
        <Container fluid className="coopcon">
          <Row>
            <Col>
              <p className="coophead">CO-OP WITH FRIENDS</p>
              <p className="cooptxt">
                Meadow Moon is not a singleplayer experience. We want you to
                befriend EVERYONE, not just the villagers, with a hub-world,
                guilds, connecting home islands and co-op experiences all around
                you we’re positive you’ll be expanding that pool of friends in
                no time.
              </p>
            </Col>
          </Row>
          <Row>
            <img src={Friends} alt="" />
          </Row>
        </Container>
        <Container fluid className="CharacterCon">
          <Row>
            <Col>
              <p className="charhead">CHARACTERS</p>
              <p className="chartxt">
                Make friends with countless villagers on the island, help them
                complete various quests,give them their favorite snack, or
                attend rare bonding experiences to really become a part of the
                community. Each villager has their own entirely unique storyline
                that intertwines in the world, so try not to pick favorites!
              </p>
            </Col>
          </Row>
          <Row className="imgwarp">
            <div className="imgcon">
              <img src={Characters} alt="" className="charimg" />
            </div>
            <img src={cloud4} alt="" className="charcloudimg" />
          </Row>
        </Container>
        <Container fluid className="fooot" id="Subscribe">
          <Row>
            <Col>
              <Row></Row>
              <Row className="foootrow">
                <h1 className="foooth1">
                  SIGN UP TO
                  <br />
                  THE MEADOW MOON ALPHA
                </h1>
                <div id="mc_embed_signup" className="subscribediv">
                  <form
                    action="https://thecoredevs.us8.list-manage.com/subscribe/post?u=b07b40da6e950b5d2ad59756e&amp;id=0bff1314e9&amp;f_id=002f07e0f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_self"
                  >
                    <div id="mc_embed_signup_scroll">
                      <div className="mc-field-group ">
                        <p>
                          Please provide your email address below and join our
                          community to stay updated
                        </p>
                        <input
                          type="email"
                          name="EMAIL"
                          className="required email EmailInput"
                          placeholder="Email Address"
                          id="mce-EMAIL"
                          required
                        />
                        <span
                          id="mce-EMAIL-HELPERTEXT"
                          className="helper_text"
                        ></span>
                      </div>
                      <div id="mce-responses" className="clear foot">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: "none" }}
                        ></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                        className="mtop"
                      >
                        <input
                          type="text"
                          name="b_b07b40da6e950b5d2ad59756e_0bff1314e9"
                          tabIndex="-1"
                        />
                      </div>
                      <div className="optionalParent">
                        <div className="clear foot">
                          <button
                            className="SubscribeBtn"
                            type="Submit"
                          ></button>
                          <p className="brandingLogo ">
                            <a
                              href="http://eepurl.com/ilbmtD"
                              title="Mailchimp - email marketing made easy and fun"
                            >
                              <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_light_dtp.svg" />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Row>
              <Row className="foootsoc">
                <p>
                  <a href="https://twitter.com/MeadowMoon">
                    <img src={twitter2} alt="" className="socfoot" />
                  </a>
                  <a href="https://Discord.gg/meadowmoon">
                    <img src={discord2} alt="" className="socfoot" />
                  </a>
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="PageM">
        <Container fluid className="WelcomeMeadow">
          <Container fluid>
            <Row className="NavBarRow">
              <Col>
                <img src={logom} alt="" className="LogoImg" />
              </Col>
              <Col>
                <Row className="align-items-end">
                  <Col className="soccol">
                    <a href="https://Discord.gg/meadowmoon">
                      <img src={discordm} alt="" className="SocImg" />
                    </a>
                    <a href="https://twitter.com/MeadowMoon">
                      <img src={twitterm} alt="" className="SocImg" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="Clouds">
            <img src={MainLogom} alt="" className="MainLogo" />
            <a href="#Subscribe">
              <button className="SignUpBtn"></button>
            </a>
          </div>
        </Container>
        <Container fluid className="CloudBG">
          <Row>
            <Col className="Welcomewrap">
              <p className="WelcomeTxt">
                Welcome to the world of Meadow Moon, an adventure full of
                villagers to befriend, land to farm on and dungeons to plunder.
                Begin your island journey by creating and customizing your
                character, completing various quests, exploring hidden realms,
                and uncovering the forgotten history of the island.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="GameplayBG">
          <Row>
            <Col className="darksht">
              <h1 className="GameplayH1">GAMEPLAY</h1>
              <p className="GameplayTxt">
                Live in harmony with the villagers of the island while farming
                crops, trading goods and creating your own island paradise. Or
                for those who seek adventure, dive into magical rifts to visit
                mysterious realms and complete various missions, from simple
                tasks like rescuing lost villagers to fighting powerful foes,
                rewarding you with otherworldly treasures.
              </p>
            </Col>
          </Row>
          <Col className="CardRow">
            <Card className="GameplayCard">
              <Card.Img variant="top" src={farmingcardm} />
              <Card.Body>
                <Card.Title className="farmingcardtxt">
                  PERSONAL ISLAND
                </Card.Title>
                <Card.Text className="carddescript">
                  Customize your personal island, Grow unique plants, Fill your
                  aquarium with exotic fish, Personalize your island with
                  various decorations and accessories.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="GameplayCard middlecard">
              <Card.Img variant="top" src={fishingcardm} />
              <Card.Body>
                <Card.Title className="fishingcardtxt">STORY</Card.Title>
                <Card.Text className="carddescript">
                  Explore both the island and various hidden realms to help
                  uncover the forgotten history of the island.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="GameplayCard">
              <Card.Img variant="top" src={dungeoncardm} />
              <Card.Body>
                <Card.Title className="dungeoncardtxt">ADVENTURE</Card.Title>
                <Card.Text className="carddescript">
                  Mysterious rifts begin to appear on the island, which appear
                  to lead to unknown realms within the cosmos. Complete complex
                  puzzles, discover exotic wildlife, unearth mystical treasures,
                  and encounter formidable bosses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Container>
        <Container fluid className="coopcon">
          <Row>
            <Col>
              <p className="coophead">CO-OP WITH FRIENDS</p>
              <img src={Friendsm} alt="" className="freindimg" />
            </Col>
          </Row>
          <Row>
            <p className="cooptxt">
              Meadow Moon is not a singleplayer experience. We want you to
              befriend EVERYONE, not just the villagers, with a hub-world,
              guilds, connecting home islands and co-op experiences all around
              you we’re positive you’ll be expanding that pool of friends in no
              time.
            </p>
          </Row>
        </Container>
        <Container fluid className="CharacterCon">
          <Row>
            <Col>
              <p className="charhead">CHARACTERS</p>
              <p className="chartxt">
                Make friends with countless villagers on the island, help them
                complete various quests,give them their favorite snack, or
                attend rare bonding experiences to really become a part of the
                community. Each villager has their own entirely unique storyline
                that intertwines in the world, so try not to pick favorites!
              </p>
            </Col>
          </Row>
          <Row className="imgwarp">
            <div className="imgcon">
              <img src={Charactersm} alt="" className="charimg" />
            </div>
            <img src={cloud4} alt="" className="charcloudimg" />
          </Row>
        </Container>
        <Container fluid className="fooot">
          <Row>
            <Col>
              <Row></Row>
              <Row className="foootrow">
                <h1 className="foooth1">
                  SIGN UP TO
                  <br />
                  THE MEADOW MOON ALPHA
                </h1>

                <div id="mc_embed_signup" className="subscribediv">
                  <form
                    action="https://thecoredevs.us8.list-manage.com/subscribe/post?u=b07b40da6e950b5d2ad59756e&amp;id=0bff1314e9&amp;f_id=002f07e0f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate "
                    target="_self"
                  >
                    <div id="mc_embed_signup_scroll">
                      <div className="mc-field-group">
                        <label htmlFor="mce-EMAIL">
                          Email Address <span className="asterisk">*</span>
                        </label>
                        <input
                          type="email"
                          value=""
                          name="EMAIL"
                          className="required email"
                          id="mce-EMAIL"
                          required
                        />
                        <span
                          id="mce-EMAIL-HELPERTEXT"
                          className="helper_text"
                        ></span>
                      </div>
                      <div id="mce-responses" className="clear foot">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: "none" }}
                        ></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="b_b07b40da6e950b5d2ad59756e_0bff1314e9"
                          tabIndex="-1"
                          value=""
                        />
                      </div>
                      <div className="optionalParent">
                        <div className="clear foot">
                          <input
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="button"
                          />
                          <p className="brandingLogo">
                            <a
                              href="http://eepurl.com/ilbmtD"
                              title="Mailchimp - email marketing made easy and fun"
                            >
                              <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_light_dtp.svg" />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Row>
              <Row className="foootsoc">
                <p>
                  <a href="https://twitter.com/MeadowMoon">
                    <img src={twitter2} alt="" className="socfoot" />
                  </a>
                  <a href="https://Discord.gg/meadowmoon">
                    <img src={discord2} alt="" className="socfoot" />
                  </a>
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
